/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import colors from '../config/color';
import Header from './header';
import './layout.css';
import { FaLine, FaFacebookSquare } from 'react-icons/fa';
import Hero from '../components/hero';
import MenuButton from '../components/menuButton';

const Layout = ({
  children,
  title,
  showHeader = true,
  showHero = false,
  breadcrumbs,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  console.log(
    // eslint-disable-next-line no-undef
    `${process.env.GATSBY_BUILD_NUMBER}.${process.env.GATSBY_BUILD_DATE}.${process.env.GATSBY_GIT_HASH}`,
  );

  return (
    <>
      {/* <MenuButton /> */}
      {showHeader ? (
        <Header
          siteTitle={title || data.site.siteMetadata.title}
          breadcrumbs={breadcrumbs}
        />
      ) : null}
      {showHero ? <Hero /> : null}
      <div className="main-content">
        <div className="main-bg"></div>
        <main>{children}</main>
      </div>

      {/* footer 樣式 */}
      <footer>
        <div className="footer-background">
          <ul className="footer-content">
            <li>
              <ul className="info-content">
                <li className="info-item">
                  <a href="/about">關於我們｜</a>
                </li>
                <li className="info-item">
                  <a href="/concat">聯絡我們｜</a>
                </li>
                <li className="info-item">
                  <a href="/disclaimer">免責聲明</a>
                </li>
              </ul>
            </li>
            <li>
              <div className="copyright">
                <p>
                  Copyright © {new Date().getFullYear()}
                  {` `}
                  <span className="c2">好物櫥窗 All Rights Reserved.</span>
                </p>
              </div>
            </li>
          </ul>
          {/* <ul className="footer-share">
            <li className="line-icon">
              <FaLine style={{ height: 40, width: 40 }} />
            </li>
            <li className="fb-icon">
              <FaFacebookSquare style={{ height: 40, width: 40 }} />
            </li>
          </ul> */}
        </div>
      </footer>

      <style jsx>{`
        footer {
          width: 100%;
          height: 150px;
          background-color: ${colors['very-light-pink']};
          display: flex;
          justify-content: center;
        }

        .copyright {
          text-align: center;
        }

        .c2 {
          line-height: 20px;
        }

        .footer-background {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column-reverse;
        }

        .footer-content {
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .info-content {
          display: flex;
          flex-direction: row;
          margin: 10px 0 10px 0;
        }

        a {
          color: ${colors['dirt-brown']};
          text-decoration: none;
          font-size: 16px;
          margin: 10px 0 10px 0;
        }

        a:hover {
          color: ${colors['dirt-brown-70%']};
        }

        p {
          color: ${colors['dirt-brown']};
          margin: 10px 0 10px 0;
          font-size: 14px;
        }

        .footer-share {
          display: flex;
          flex-direction: row;
          padding-top: 10px;
          color: ${colors['dirt-brown']};
        }

        main {
          margin: 0 auto;
          max-width: 1200px;
          background-color: #fff;
          border-radius: 50px;
          -webkit-box-shadow: 0px -10px 20px -10px rgba(199, 199, 199, 0.69);
          -moz-box-shadow: 0px -10px 20px -10px rgba(199, 199, 199, 0.69);
          box-shadow: 0px -10px 20px -10px rgba(199, 199, 199, 0.69);
        }

        .main-content {
          background-color: #fff;
          border-radius: 50px;
          position: relative;
          padding-bottom: 60px;
        }

        .main-bg {
          width: 100%;
          height: 30%;
          background-color: ${colors['very-light-pink']};
          position: absolute;
          top: 0;
          z-index: -1;
        }

        @media (min-width: 579px) {
        }

        @media (min-width: 768px) {
          .copyright {
            text-align: left;
          }

          .c2 {
            line-height: 20px;
          }

          .footer-content {
            align-items: flex-start;
          }
          .footer-background {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 600px;
          }

          .footer-share {
            padding-top: 0px;
          }
        }

        @media (min-width: 992px) {
          .footer-background {
            width: 900px;
          }
        }

        @media (min-width: 1200px) {
          main {
            -webkit-box-shadow: 0px 0px 0px 0px #ffffff;
            -moz-box-shadow: 0px 0px 0px 0px #ffffff;
            box-shadow: 0px 0px 0px 0px #ffffff;
          }

          .main-content {
            -webkit-box-shadow: 0px -10px 20px -10px rgba(199, 199, 199, 0.69);
            -moz-box-shadow: 0px -10px 20px -10px rgba(199, 199, 199, 0.69);
            box-shadow: 0px -10px 20px -10px rgba(199, 199, 199, 0.69);
          }
        }
      `}</style>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbs: PropTypes.array,
};

Layout.defaultProps = {
  breadcrumbs: [],
};

export default Layout;
