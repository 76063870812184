import React, { useState } from 'react';
import colors from '../config/color';
import { GoSearch } from 'react-icons/go';

const SearchBar = props => {
  // eslint-disable-next-line no-empty-pattern
  const {} = props;
  const [searchText, setText] = useState('');

  const handleChange = e => {
    setText(e.target.value);
  };

  return (
    <div className="search-content">
      <input
        placeholder="尋找好物"
        className="content-search-text"
        value={searchText}
        onChange={handleChange}
      ></input>
      <div
        className="content-search-img"
        onClick={() => {
          window.open(`https://wowindow.tw/search?keyword=${searchText}`);
        }}
      >
        <GoSearch style={{ height: 25, width: 25 }} />
      </div>

      <style jsx>{`
        .search-content {
          position: relative;
        }

        .content-search-text {
          /* width: 308px; */
          width: calc(100% - 65px);
          height: 45px;
          font-size: 14px;
          background-color: ${colors['very-light-pink']};
          border-radius: 100px;
          border-width: 0px;
          outline: none;
          padding: 0 45px 0 20px;
        }

        .content-search-img {
          position: absolute;
          right: 15px;
          top: 10px;
          color: ${colors['dirt-brown']};
        }

        .content-search-img:hover {
          cursor: pointer;
        }

        @media (min-width: 579px) {
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 924px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

SearchBar.propTypes = {};

SearchBar.defaultProps = {};

export default SearchBar;
