import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FaBars } from 'react-icons/fa';
import SearchBar from './searchBar';
import colors from '../config/color';
import logoSvg from '../images/header/logo.svg';
import css from 'styled-jsx/css';

const breadcrumbLink = css.resolve`
  a {
    color: ${colors['dirt-brown']};
    text-decoration: none;
    font-size: 14px;
  }
`;

const Breadcrumb = ({ data = [{}] }) => {
  const items = data.map(o => {
    const { url, name } = o;
    return (
      <Link
        className={`${breadcrumbLink.className}`}
        key={`breadcrumb_${name}`}
        to={url}
      >
        {name}
      </Link>
    );
  });
  return items.reduce((prev, curr) => [prev, '、', curr]);
};

const Header = ({ breadcrumbs }) => {
  return (
    <header>
      <div className="header-fixed">
        <span>
          <Link to={'/'}>
            <img alt="logo" className="logo" src={logoSvg} />
          </Link>
        </span>
        <div className="breadcrumbs">
          <Breadcrumb data={[{ name: '首頁', url: '/' }]} />
          {breadcrumbs.length > 0 ? ' > ' : ''}
          {breadcrumbs.length > 0 &&
            breadcrumbs
              .map((breadcrumb, i) => {
                return (
                  <Breadcrumb key={`breadcrumbs_${i}`} data={breadcrumb.data} />
                );
              })
              .reduce((prev, curr) => [prev, ' > ', curr])}
        </div>
      </div>
      {/* <div className="menu-icon">
        <FaBars style={{ height: 25, width: 25 }} />
      </div> */}
      <div className="search-bar">
        <SearchBar />
      </div>
      {breadcrumbLink.styles}
      <style jsx>{`
        header {
          background-color: ${colors['very-light-pink']};
          padding-bottom: 80px;
          height: 30px;
          position: relative;
        }

        .header-fixed {
          position: fixed;
          left: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: calc(100% - 40px);
          max-width: 900px;
          margin: 20px;
        }

        .logo {
          width: 130px;
          z-index: 99;
          margin: auto;
        }

        .logo:hover {
          cursor: pointer;
        }

        .breadcrumbs {
          font-size: 14px;
          line-height: 20px;
          color: ${colors['dirt-brown']};
          margin-top: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 90%;
          cursor: pointer;
        }

        .search-bar {
          display: none;
          position: absolute;
          right: 20px;
          top: 20px;
        }

        .menu-icon {
          position: absolute;
          right: 20px;
          top: 20px;
          color: ${colors['dirt-brown']};
        }

        @media (min-width: 579px) {
          .logo {
            margin: 0;
          }

          .search-bar {
            display: inline;
            width: 180px;
            border: 1px solid ${colors['dirt-brown']};
            border-radius: 100px;
          }

          .breadcrumbs {
            width: 70%;
          }

          .menu-icon {
            display: none;
          }
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 924px) {
          .header-fixed {
            left: calc(50% - 450px);
            margin: 20px 0;
          }
          .search-bar {
            right: calc(50% - 450px);
          }
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </header>
  );
};

Header.propTypes = {
  breadcrumbs: PropTypes.array,
};

Header.defaultProps = {
  breadcrumbs: [
    {
      data: [
        {
          name: '3C',
          url: 'category/3c/1/',
        },
        {
          name: '旅遊',
          url: 'category/travel/1/',
        },
      ],
    },
    {
      data: [
        {
          name: '網路租借推薦',
          url: `/${decodeURIComponent('網路租借推薦')}/`,
        },
      ],
    },
  ],
};

export default Header;
