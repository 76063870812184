import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import colors from '../config/color';
import ProductCard from './productCard';

const Product = props => {
  const { edges, title } = props;
  if (edges.length < 1) return null;
  return (
    <div className="product">
      <h2>{title}</h2>
      <div className="product-content">
        {edges.map(({ node }) => (
          <div key={node.slug} className="product-card">
            <ProductCard
              slug={node.slug}
              imageUri={node.featured_media.source_url}
              imageAlt={node.featured_media.title}
              title={node.title}
              categories={_.filter(node.categories, o => o.slug !== 'noad')}
            />
          </div>
        ))}
      </div>

      <style jsx>{`
        h2 {
          font-size: 24px;
          color: ${colors['dirt-brown']};
          margin: 80px 0px 20px 0px;
          display: flex;
          justify-content: center;
        }

        .product {
          display: flex;
          align-items: center;
          flex-direction: column;
        }

        .product-content {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        }

        .product-card {
          margin: 20px;
        }

        @media (min-width: 0px) and (max-width: 615px) {
          .product-content {
            flex-direction: column;
            width: 100%;
          }
          .product-card {
            width: 100%;
            margin: 0px;
            margin-bottom: 20px;
          }
        }

        @media (min-width: 616px) and (max-width: 768px) {
        }
        @media (min-width: 769px) and (max-width: 992px) {
        }
        @media (min-width: 993px) and (max-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

Product.propTypes = {
  edges: PropTypes.array,
  title: PropTypes.string,
};

Product.defaultProps = {
  edges: [],
  title: '',
};

export default Product;
