/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
  metaType,
  metaUrl,
  description,
  lang,
  meta,
  title,
  image,
  graph,
  isNoAd,
  schema,
  canonicalUrl,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const siteUrl = site.siteMetadata.siteUrl;
  let ldjson = [
    {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      '@id': siteUrl,
      url: siteUrl,
      name: site.siteMetadata.title,
      description: site.siteMetadata.description,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${siteUrl}/search?keyword={search_term_string}`,
        'query-input': 'required name=search_term_string',
      },
    },
  ];
  if (schema) {
    ldjson = ldjson.concat(schema);
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, maximum-scale=1',
        },
        {
          name: 'robots',
          content:
            'max-snippet:-1, max-image-preview:large, max-video-preview:-1',
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: 'zh_TW',
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: metaType || `website`,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:image`,
          content: image, // TODO: 圖片
        },
        {
          property: `og:url`,
          content: `${siteUrl}/${metaUrl}` || siteUrl,
        },
        // {
        //   name: `twitter:card`,
        //   content: `summary`,
        // },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
        // {
        //   name: `twitter:title`,
        //   content: title,
        // },
        // {
        //   name: `twitter:description`,
        //   content: metaDescription,
        // },
      ].concat(meta)}
    >
      {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null}
      {isNoAd ? null : (
        <script
          data-ad-client={process.env.GATSBY_AD_CLIENT}
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
      )}
      {ldjson.map(o => {
        // eslint-disable-next-line react/jsx-key
        return <script type="application/ld+json">{JSON.stringify(o)}</script>;
      })}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `zh-cmn-Hant`,
  meta: [],
  description: ``,
  image: '',
  graph: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  graph: PropTypes.array,
};

export default SEO;
